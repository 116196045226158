import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { KeycloakProfile } from 'keycloak-js';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as currencyData from 'cldr-data/supplemental/currencyData.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as currencies from 'cldr-data/main/de/currencies.json';

import { ProjectDataCacheService } from './project-data-cache.service';
import { DataService } from './shared/services/data.service';
import { InteractionsService } from './shared/services/interactions.service';
import { AuthService } from './shared/services/auth.service';
import { SharedService } from './shared/services/shared.service';
import { Employee } from './entities/employees/models/employee.model';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';

loadCldr(numberingSystems, currencyData, numbers, currencies);

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@Input() public prefix: string = '';

  public currentComponentName: string;

	public isSideBarOpen: boolean = true;
	public collapsed: boolean = false;
	public mobileMenuOpen: boolean = false;

	public userProfile?: KeycloakProfile;
	public isAdmin: boolean = false;
	public isBranchAdmin: boolean = false;

	public employees: Employee[] = [];
	public fields: Object = { text: '', value: 'id' };
	public selectedUserId?: string;
	public selectedUser?: Employee;

	defaultImageSrc = '../assets/teamtime-Logo-2024.png';
	imageSrc = this.defaultImageSrc;
	isEditable:boolean = false;
	companyName:string = 'Teamtime';

	public constructor(
		public readonly projectDataCacheService: ProjectDataCacheService,
		public readonly dataService: DataService,
		public readonly interactionsService: InteractionsService,
		protected authService: AuthService,
		public router: Router,
		private route: ActivatedRoute,
		private sharedService: SharedService,
		private cdr: ChangeDetectorRef
	) {
	}

	public async ngOnInit() {
		showSpinner((document as any).getElementById('app'));

		this.authService.currentUser$.subscribe(() => {
			this.dataService.startTokenExpireTimer();
		});

		L10n.load({
			'de': {
				'numerictextbox': {
					incrementTitle: 'Wert erhöhen', decrementTitle: 'Dekrementwert'
				},
				'calendar': {
					today: 'Heute'
				},
				'schedule': {
					'day': 'Tag',
					'week': 'Woche',
					'workWeek': 'Arbeitswoche',
					'month': 'Monat',
					'agenda': 'Agenda',
					'weekAgenda': 'Wochenagenda',
					'workWeekAgenda': 'Arbeitswochenagenda',
					'monthAgenda': 'Monatsagenda',
					'today': 'Heute',
					'noEvents': 'Keine Ereignisse',
					'allDay': 'Ganztägig',
					'weekNumber': 'Woche Nummer'
				},
				'daterangepicker': {
					placeholder: 'Wählen Sie einen Zeitraum',
					startLabel: 'Anfangsdatum',
					endLabel: 'Enddatum',
					applyText: 'Anwenden',
					cancelText: 'Abbrechen',
					selectedDays: 'Ausgewählte Tage',
					days: 'Tage',
					customRange: 'Benutzerdefinierter Bereich'
				},
				'gantt': {
					'emptyRecord': 'Keine Datensätze angezeigt',
					'id': 'ID',
					'name': 'Name',
					'startDate': 'Anfangsdatum',
					'endDate': 'Enddatum',
					'duration': 'Dauer',
					'progress': 'Fortschritt',
					'dependency': 'Abhängigkeit',
					'notes': 'Notizen',
					'baselineStartDate': 'Baseline-Startdatum',
					'baselineEndDate': 'Baseline-Enddatum',
					'taskMode': 'Aufgabenmodus',
					'changeSchedule': 'Ändern Zeitplan',
					'work': 'Arbeit',
					'taskType': 'Aufgabentyp',
					'type': 'Typ',
					'filter': 'Filter',
					'clearFilter': 'Filter löschen',
					'start': 'Start',
					'end': 'Ende',
					'search': 'Suche',
					'add': 'Hinzufügen',
					'edit': 'Bearbeiten',
					'delete': 'Löschen',
					'update': 'Aktualisieren',
					'cancel': 'Stornieren',
					'save': 'Speichern',
					'taskInformation': 'Aufgabeninformation'
				},
				'grid': {}
			}
		});

		this.dataService.dynamicMediaService.updated$.subscribe(()=>{
			const dynamicMedia = this.dataService.dynamicMediaCacheService.dynamicMedia.find((media)=>media.id==='company');
			if(dynamicMedia){
				this.imageSrc = dynamicMedia.media ? this.sharedService.convertImagetoUrl(dynamicMedia.media): this.defaultImageSrc;
				this.companyName = dynamicMedia.name || 'Teamtime';
			}else{
				this.interactionsService.dynamicMediaService.create({id:'company'})
			}
			hideSpinner((document as any).getElementById('app'));
		})

		this.sharedService.currentComponentName.subscribe(name => {
			this.currentComponentName = name;
			this.cdr.detectChanges();
		});

		this.userProfile = await this.authService.getUserKeycloakProfile();

		if (this.userProfile && this.userProfile.id) {
			this.interactionsService.abscencesService.getsubstitutionRequest(this.userProfile.id);
		}

		this.isAdmin = this.authService.isAdmin();
		this.isBranchAdmin = this.authService.isBranchAdmin();

		this.dataService.employeesService.updated$.subscribe(() => {
			const savedUserId = localStorage.getItem('selectedUserId');

			if ((this.isAdmin || this.isBranchAdmin) && savedUserId) {
				const matchedUser = this.dataService.employeesCacheService.employees.find((user: Employee) => user.id === savedUserId);
				if (matchedUser) {
					this.selectedUser = matchedUser;
					this.selectedUserId = matchedUser.id;
				}
				this.employees = this.dataService.employeesCacheService.employees;
				if (this.isBranchAdmin) {
					this.employees = this.employees.filter(e => e?.outlet === matchedUser?.outlet);
				}
			} else if (this.userProfile && this.userProfile.id) {
				const matchedUser = this.dataService.employeesCacheService.employees.find((user: Employee) => user.id === this.userProfile!.id);
				if (matchedUser) {
					this.selectedUser = matchedUser;
					this.selectedUserId = matchedUser.id;
				}
				if(this.isAdmin || this.isBranchAdmin){
					this.employees = this.dataService.employeesCacheService.employees;
					if (this.isBranchAdmin) {
						this.employees = this.employees.filter(e => e?.outlet === matchedUser?.outlet);
					}
				}
			}

			this.updateSelectedUser(() => {
				this.authService.getSelectedEmployee();
			});
		});
	}

	ngAfterViewInit() {
		createSpinner({
		  target: document.getElementById('app') as any
		});
		showSpinner((document as any).getElementById('app'));
	}

	public toggleSideBar(): void {
		this.collapsed = !this.collapsed;
	}

	public toggleMobileMenu(): void {
		this.mobileMenuOpen = !this.mobileMenuOpen;
	}

	public onChangeUser(): void {
		this.updateSelectedUser(() => {
			this.authService.updateSelectedEmployee();
			localStorage.setItem('selectedUserId', this.selectedUserId!);
		});
	}

	public async logout(): Promise<void> {
		await this.authService.logout();
	}

	public updateSelectedUser(fn: () => void): void {
		this.selectedUser = this.dataService.employeesCacheService.employees.find((user: Employee) => user.id === this.selectedUserId);

		if (!this.selectedUser) {
			return;
		}

		this.authService.selectedUser = this.selectedUser;

		fn();
	}

	triggerFileInput() {
		if(this.authService.currentEmployee.role !== 'admin'){
			return
		}
		const fileInput = document.getElementById(
		  'imgUploadInput'
		) as HTMLInputElement;
		fileInput.click();
	}

	onFileChange(event: any) {
		const file = event.target.files[0];
		const formData: any = new FormData();
		formData.append('file', file);

		this.interactionsService.dynamicMediaService.update('company', formData);

	}

	changeCompanyName(){
		this.isEditable = false;
		const formData: any = new FormData();
		formData.append('name', this.companyName);
		this.interactionsService.dynamicMediaService.update('company', formData);
	}
}
