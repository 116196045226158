// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import 'moment/locale/de';  // Import the German locale

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	private componentName = new BehaviorSubject<string>('Zeiterfassung');
	currentComponentName = this.componentName.asObservable();

	changeComponentName(name: string) {
		this.componentName.next(name);
	}

	convertToUTC(date: Date): Date {
		console.log('Date before conversion: ', date);
		console.log('UTC Date after conversion: ', moment(date).utc().toDate());
		// Convert the local date to UTC
		return moment(date).utc().toDate();
	}

	convertToLocalTime(utcDateString: string): Date {
		// Convert the UTC date string back to local time
		return moment.utc(utcDateString).local().toDate();
	}

	convertImagetoUrl(imageData:any){
		const bytes = new Uint8Array(imageData.data);
		let binary = '';
		bytes.forEach(byte => {
		  binary += String.fromCharCode(byte);
		});
		return 'data:image/*;base64,' + btoa(binary);
	  }
}
