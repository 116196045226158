import { DestroyRef, Injectable, inject } from '@angular/core'

import { EngineService } from '../../engine/services/engine.service'
import { EmployeesService } from '../../entities/employees/services/employees.service'
import { EmployeesCacheService } from '../../entities/employees/services/employees-cache.service'
import { TimeEntriesService } from '../../entities/time-entries/services/time-entries.service'
import { TimeEntriesCacheService } from '../../entities/time-entries/services/time-entries-cache.service'
import { TargetVacationDaysesService } from '../../entities/target-vacation-dayses/services/target-vacation-dayses.service'
import { TargetVacationDaysesCacheService } from '../../entities/target-vacation-dayses/services/target-vacation-dayses-cache.service'
import { TargetWorkingHoursesService } from '../../entities/target-working-hourses/services/target-working-hourses.service'
import { TargetWorkingHoursesCacheService } from '../../entities/target-working-hourses/services/target-working-hourses-cache.service'
import { AbscencesService } from '../../entities/abscences/services/abscences.service'
import { AbscencesCacheService } from '../../entities/abscences/services/abscences-cache.service'
import { HolidaysService } from '../../entities/holidays/services/holidays.service'
import { HolidaysCacheService } from '../../entities/holidays/services/holidays-cache.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import {TimeEntriesHttpRequestService} from "../../entities/time-entries/services/time-entries-http-request.service";
import {EmployeesHttpRequestService} from "../../entities/employees/services/employees-http-request.service";
import {AbscencesHttpRequestService} from "../../entities/abscences/services/abscences-http-request.service";
import { DynamicMediaService } from 'src/app/entities/dynamic-media/services/dynamic-media.service'
import { DynamicMediaCacheService } from 'src/app/entities/dynamic-media/services/dynamic-media-cache.service'
import { Subscription, timer } from 'rxjs'
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private logoutSubscription: Subscription | null = null;

	destroyRef = inject(DestroyRef);
  // Inject the needed entity cache services here. Like for example CatCacheService.
	public constructor(
		private readonly engineService: EngineService,
		public readonly employeesService: EmployeesService,
		public readonly employeesHttpService: EmployeesHttpRequestService,
		public readonly employeesCacheService: EmployeesCacheService,
		public readonly timeEntriesService: TimeEntriesService,
		public readonly timeEntriesCacheService: TimeEntriesCacheService,
		public readonly timeEntriesHttpService: TimeEntriesHttpRequestService,
		public readonly targetVacationDaysesService: TargetVacationDaysesService,
		public readonly targetVacationDaysesCacheService: TargetVacationDaysesCacheService,
		public readonly targetWorkingHoursesService: TargetWorkingHoursesService,
		public readonly targetWorkingHoursesCacheService: TargetWorkingHoursesCacheService,
		public readonly abscencesService: AbscencesService,
		public readonly abscencesHttpService: AbscencesHttpRequestService,
		public readonly abscencesCacheService: AbscencesCacheService,
		private readonly holidaysService: HolidaysService,
		public readonly holidaysCacheService: HolidaysCacheService,
		public readonly dynamicMediaService: DynamicMediaService,
		public readonly dynamicMediaCacheService: DynamicMediaCacheService,
		private readonly router: Router,
		private readonly authService: AuthService
	) {
		this.engineService.initialize()

		this.engineService.error$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((error) => console.error(error))

		this.engineService.loaded$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.reload()
			this.employeesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.timeEntriesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.targetVacationDaysesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.targetWorkingHoursesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.abscencesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.holidaysService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
			this.dynamicMediaService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload())
		})

		this.dynamicMediaService.findAll()
		this.employeesService.findAll()
		this.timeEntriesService.findAll()
		this.targetVacationDaysesService.findAll()
		this.targetWorkingHoursesService.findAll()
		this.abscencesService.findAll()
		this.holidaysService.findAll()

		this.engineService.emitLoaded()
	}

	public reloadEmployees(){
		this.employeesService.findAll();
	}

	public reloadTargetVacations() {
		this.targetVacationDaysesService.findAll();
	}

	public reloadWorkingHours() {
		this.targetWorkingHoursesService.findAll();
	}

	public reloadAbsences(){
		this.abscencesService.findAll();
		this.engineService.emitLoaded();
	}

	public reload(): void {
	}

	isRunning: boolean = false;

	startTokenExpireTimer() {
		if (this.isRunning) {
			return;
		}

		const currentUserString = localStorage.getItem('currentUser');
	
		if (!currentUserString) {
			return true;
		}

		this.isRunning = true;
	
		try {
			const currentUser = JSON.parse(currentUserString);
			if (currentUser?.access_token) {
				const decodedToken: any = jwtDecode(currentUser.access_token);
				const tokenExpiryTime = decodedToken?.exp;
				const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
	
				const expiryDuration = (tokenExpiryTime - currentTimestamp) * 1000;
	
				if (expiryDuration <= 0) {
					localStorage.removeItem('currentUser');
					this.authService.logout();
					this.isRunning = false;
					return;
				}
	
				this.startLogoutTimer(expiryDuration);
			}
		} catch (error) {
			console.error('Error decoding token: ', error);
			localStorage.removeItem('currentUser');
			this.isRunning = false;
			return true;
		}
	
		return true;
	}
	
	startLogoutTimer(expiresIn: number) {
		if (this.logoutSubscription) {
			this.logoutSubscription.unsubscribe();
		}
	
		this.logoutSubscription = timer(expiresIn).subscribe(() => {
			localStorage.removeItem('currentUser');
			this.authService.logout();
		});
	}
}
