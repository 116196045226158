import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subject } from 'rxjs';

import { Employee } from '../../entities/employees/models/employee.model';
import { EmployeesService } from 'src/app/entities/employees/services/employees.service';
import { EmployeesCacheService } from 'src/app/entities/employees/services/employees-cache.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public selectedUser: Employee;

	public currentUser$: Subject<Employee> = new Subject<Employee>();
	public selectedUser$: Subject<Employee> = new Subject<Employee>();

	public currentEmployee: Employee;
	public selectedEmployee: Employee;

	public constructor(
		private readonly angularKeycloak: KeycloakService,
		private readonly employeesService: EmployeesService,
		private readonly employeesCacheService: EmployeesCacheService
	) {
		this.getSelectedEmployee();

		this.employeesService.updated$.subscribe(async () => {
			await this.updateEmployeeAdminMode();
			await this.updateSelectedEmployee();
		});
	}

	public async getSelectedEmployee() {
		await this.updateEmployeeAdminMode();
		await this.updateSelectedEmployee();
	}

	public async updateEmployeeAdminMode() {
		const currentKeycloakEmployee = await this.getUserKeycloakProfile();
		const currentKeycloakEmployeeAccessToken = await this.angularKeycloak.getToken();

		let currentEmployee = this.employeesCacheService.employees.find((employee: Employee) => employee.id === currentKeycloakEmployee.id);

		if (currentEmployee) {
			this.currentEmployee = currentEmployee;
			this.currentUser$.next(currentEmployee);
		}

		localStorage.setItem('currentUser', JSON.stringify({ access_token: currentKeycloakEmployeeAccessToken }));
	}

	public async updateSelectedEmployee() {
		if (
			this.selectedUser
			&& this.selectedUser.id
			&& this.employeesCacheService.employees
			&& this.employeesCacheService.employees.length > 0
		) {
			let selectedEmployee = this.employeesCacheService.employees.find((employee: Employee) => employee.id === this.selectedUser.id);

			if (selectedEmployee) {
				this.selectedEmployee = selectedEmployee;
				this.selectedUser$.next(selectedEmployee);
			}
		}
	}

	public isLoggedIn(): boolean {
		return this.angularKeycloak.isLoggedIn();
	}

	public isAdmin(): boolean {
		return this.angularKeycloak.isUserInRole('admin');
	}

	public isBranchAdmin(): boolean {
		return this.angularKeycloak.isUserInRole('branch-admin');
	}

	public async getUserKeycloakProfile(): Promise<KeycloakProfile> {
		return await this.angularKeycloak.loadUserProfile();
	}

	public async logout(): Promise<void> {
		await this.angularKeycloak.logout();
	}
}
