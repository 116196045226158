import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewChild, inject, OnDestroy } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { loadCldr } from '@syncfusion/ej2-base'
import { EventRenderedArgs, EventSettingsModel, ScheduleComponent, View } from '@syncfusion/ej2-angular-schedule'
import { ToastUtility } from "@syncfusion/ej2-notifications";
import { Subject, takeUntil } from 'rxjs'

import { ProjectDataCacheService } from 'src/app/project-data-cache.service'
import { DataService } from 'src/app/shared/services/data.service'
import { InteractionsService } from 'src/app/shared/services/interactions.service'
import * as gregorian from '../../../assets/de/ca-gregorian.json'
import * as numberingSystems from '../../../assets/de/numberingSystems.json'
import * as numbers from '../../../assets/de/numbers.json'
import * as timeZoneNames from '../../../assets/de/timeZoneNames.json'
import { TimeEntry } from 'src/app/entities/time-entries/models/time-entry.model'
import { Employee } from "../../entities/employees/models/employee.model";
import { AuthService } from "../../shared/services/auth.service";
import { SharedService } from "../../shared/services/shared.service";
import { TimeService } from 'src/app/shared/services/time.service'

loadCldr(numberingSystems['default'], gregorian['default'], numbers['default'], timeZoneNames['default']);

@Component({
	selector: 'app-zeiterfassung',
	templateUrl: './zeiterfassung.component.html',
	styleUrls: ['./zeiterfassung.component.scss', '../../../styles/material3.css']
})
export class ZeiterfassungComponent implements OnInit, OnDestroy {

	@ViewChild('scheduleObj') public scheduleObj: ScheduleComponent | null;

	@Input() public prefix: string = '';

	destroyRef = inject(DestroyRef);

	public eventSettings: EventSettingsModel = {
		fields: {
			subject: { name: 'Subject', default: 'Arbeitszeit' }
		},
		dataSource: []
	};

	public scheduleView: View = 'Week';

	currentEmployee: Employee
	form: FormGroup;
	pauseWarningMessage: string;
	private $destroy = new Subject<void>();

	getControl(controlName: string) {
		return this.form.controls[controlName] as FormControl
	}

	getControlErrors(controlName: string, errorName: string) {
		const errors = this.getControl(controlName).errors;

		if (!errors) {
			return false;
		}

		const error = errors[errorName];

		return error;
	}

	public get selectedDate(): Date {
		return this.timeService.selectedDate;
	}

	public set selectedDate(date: Date) {
		this.timeService.selectedDate = date;
	}

	set dateValue(date: Date) {
		this.form.controls['date'].setValue(date);
	}

	get dateValue() {
		return this.form?.controls['date'].value;

	}

	get fromValue() {
		return this.form?.controls['from'].value;
	}

	set fromValue(date: Date) {
		this.form.controls['from'].setValue(date);
	}

	get toValue() {
		return this.form?.controls['to'].value;
	}

	set toValue(date: Date) {
		this.form.controls['to'].setValue(date);
	}

	get pauseValue() {
		return this.form?.controls['pause'].value;
	}

	set pauseValue(date: Date) {
		this.form.controls['pause'].setValue(date);
	}

	get commentValue() {
		return this.form.controls['comment'].value;
	}

	set commentValue(date: Date) {
		this.form.controls['comment'].setValue(date);
	}


	public constructor(
		public readonly projectDataCacheService: ProjectDataCacheService,
		public readonly interactionsService: InteractionsService,
		public readonly dataService: DataService,
		private fb: FormBuilder,
		private change: ChangeDetectorRef,
		private readonly authService: AuthService,
		private sharedService: SharedService,
		public readonly timeService: TimeService
	) {
		this.form = this.fb.group({
			date: [new Date(), Validators.required],
			from: ['', [Validators.required]],
			to: ['', [Validators.required]],
			pause: ['', [this.isPauseTimeGreaterThanTotalTime()]],
			comment: [''],
		}, {
			validators: [this.isGreaterThanValidator(), this.pauseRequiredValidator()]
		});
	}

	public async ngOnInit() {
		this.sharedService.changeComponentName('Zeiterfassung');

		if (!this.currentEmployee) {
			this.currentEmployee = this.authService.selectedEmployee;
			this.timeService.currentEmployee = this.currentEmployee;

			this.dataService.timeEntriesService.findAll();
			const s2 = this.interactionsService.timeEntriesService.updated$.subscribe(() => {
				this.timeService.loadTimeEntries();
				this.timeService.calculateTargetWorkingHours(this.currentEmployee);
				this.timeService.calculateValues(this.timeService.timeEntries);
				this.updateEverything(this.currentEmployee?.timeEntries || []);
				s2.unsubscribe();
			});
		}

		const s1 = this.authService.selectedUser$.subscribe((employee: Employee) => {
			this.currentEmployee = employee;
			this.timeService.currentEmployee = this.currentEmployee;

			this.dataService.timeEntriesService.findAll();
			const s2 = this.interactionsService.timeEntriesService.updated$.subscribe(() => {
				this.timeService.loadTimeEntries();
				this.timeService.calculateTargetWorkingHours(this.currentEmployee);
				this.timeService.calculateValues(this.timeService.timeEntries);
				this.updateEverything(this.currentEmployee?.timeEntries || []);
				s2.unsubscribe();
			});

			s1.unsubscribe();
		});
	}

	public updateEverything(timeEntries): void {
		this.updateScheduler();
		this.timeService.calculateValues(timeEntries);
	}

	public updateScheduler(): void {
		let currentEmployee = this.currentEmployee;
	
		if (currentEmployee && this.scheduleObj) {
			const dataSource = this.dataService.timeEntriesCacheService.timeentries
				.map((timeEntry: TimeEntry) => ({
					Id: timeEntry.id,
					Guid: timeEntry.id,
					Subject: timeEntry.comment,
					StartTime: new Date(
						timeEntry.date.getFullYear(),
						timeEntry.date.getMonth(),
						timeEntry.date.getDate(),
						+timeEntry.from.split(':')[0],
						+timeEntry.from.split(':')[1]
					),
					EndTime: new Date(
						timeEntry.date.getFullYear(),
						timeEntry.date.getMonth(),
						timeEntry.date.getDate(),
						+timeEntry.to.split(':')[0],
						+timeEntry.to.split(':')[1]
					),
					Employee: timeEntry.employee,
				}))
				.filter((entry) => entry?.Employee?.id === currentEmployee?.id);
	
			if (this.scheduleObj?.eventSettings) {
				this.scheduleObj.eventSettings.dataSource = dataSource;
				this.change.detectChanges();
			} else {
				console.log('eventSettings is not initialized.');
			}
		} else {
			console.log('scheduleObj or currentEmployee is not initialized.');
		}
	}

	public submitTimeEntry(): void {
		if (!this.dateValue || !this.fromValue || !this.toValue) {
			return;
		}

		const create: Partial<TimeEntry> = {
			date: this.dateValue,
			from: this.fromValue.toLocaleTimeString(),
			to: this.toValue.toLocaleTimeString(),
			pause: this.pauseValue ? this.pauseValue.toLocaleTimeString() : '00:00:00',
			comment: this.commentValue as any,
			employee: this.currentEmployee
		};

		this.dataService.timeEntriesHttpService.create(create).pipe(takeUntil(this.$destroy)).subscribe({
			next: (entries) => {
				console.log('Time Entry created', entries);
				this.dataService.timeEntriesService.findAll();
				this.timeService.calculateValues(this.timeService.timeEntries);
				this.change.detectChanges();

				setTimeout(() => {
					this.timeService.loadTimeEntries();
					this.timeService.calculateTargetWorkingHours(this.currentEmployee);
					this.timeService.calculateValues(this.timeService.timeEntries);
					this.updateEverything(this.currentEmployee.timeEntries || []);
				}, 500);
			},
			error: (error) => {
				ToastUtility.show(error.error.message, 'Error', 10000);
			}
		});
	}

	public onActionComplete(args: EventRenderedArgs): void {
		const isCreate = (args as any).addedRecords && (args as any).addedRecords.length > 0
		const isUpdate = (args as any).changedRecords && (args as any).changedRecords.length > 0
		const isDelete = (args as any).deletedRecords && (args as any).deletedRecords.length > 0

		if (isCreate) this.createTimeEntry(args.data[0])
		else if (isUpdate) this.updateTimeEntry(args.data[0])
		else if (isDelete) this.deleteTimeEntry(args.data[0])
	}

	createTimeEntry(createdObject: any): void {

		const updatedId = createdObject.Id

		// Map back to a TimeEntry object
		this.interactionsService.timeEntriesService.create({

			id: updatedId,
			date: createdObject.StartTime,
			from: createdObject.StartTime.toLocaleTimeString(),
			to: createdObject.EndTime.toLocaleTimeString(),
			comment: createdObject.Subject,
			pause: '00:00:00',
			employee: this.currentEmployee
		})

		// Recalculate overtime and update the employee
		this.timeService.calculateValues(this.timeService.timeEntries);
	}


	deleteTimeEntry(deletedObject: any): void {
		const updatedId = deletedObject.Id;

		// Find the time entry object being deleted
		const deletedEntry = this.dataService.timeEntriesCacheService.timeentries.find(timeEntry => timeEntry.id === updatedId);

		if (deletedEntry) {
			// Map back to a TimeEntry object for deletion
			this.dataService.timeEntriesHttpService.delete({
				id: updatedId,
				date: deletedEntry.date,
				from: deletedEntry.from,
				to: deletedEntry.to,
				comment: deletedEntry.comment,
				pause: deletedEntry.pause,
			}).pipe(takeUntil(this.$destroy)).subscribe((entries) => {
				console.log('Time Entry deleted', entries);

				setTimeout(() => {
					this.dataService.timeEntriesService.findAll();

					// Update the employee's overtime
					this.timeService.calculateValues(this.timeService.timeEntries);

					this.change.detectChanges();
				}, 1000);
			});
		}
	}

	updateTimeEntry(changedObject: any): void {

		const updatedId = changedObject.Id

		// Map back to a TimeEntry object
		this.interactionsService.timeEntriesService.update({

			id: updatedId,
			date: changedObject.StartTime,
			from: changedObject.StartTime.toLocaleTimeString(),
			to: changedObject.EndTime.toLocaleTimeString(),
			comment: changedObject.Subject,
			pause: this.dataService.timeEntriesCacheService.timeentries.find(timeEntry => timeEntry.id === updatedId)!.pause,
		})
	}

	public onEventRendered(args: EventRenderedArgs): void {

		const categoryColor: string = args.data['CategoryColor'] as string

		if (!args.element || !categoryColor) return
		if (this.scheduleObj && this.scheduleObj?.currentView === 'Agenda') {

			(args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor
		} else {

			args.element.style.backgroundColor = categoryColor
		}
	}

	isPauseTimeGreaterThanTotalTime(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = this.toValue;
			const from = this.fromValue;
			const check = control.value ? control.value as Date : null;
			if (!to || !from || !check) {
				return null;
			}
			const diffMs = to.getTime() - from.getTime();
			const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes

			const checkTime = check.getHours() * 60 + check.getMinutes();

			return (checkTime < diffMins) ? null : {total: true};
		}
	}

	isGreaterThanValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = control.get('to');
			const from = control.get('from');

			if (!to || !from) {
				return null;
			}

			if (!to.value || !from.value) {
				return null;
			}

			const toMs = to.value.getTime();
			const fromMs = from.value.getTime();

			const isValid = toMs > fromMs;

			if (isValid) {
				to.setErrors(null);
				from.setErrors(null);
				return null;
			} else {
				to.setErrors({ range: true });
				from.setErrors({ range: true });
				return { range: true };
			}
		}
	}

	pauseRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (this.isPauseRequired()) {
				return {pauseRequired: true};
			}
			return null;
		};
	}

	calculatePauseMins(pause: Date): number {
		if (!pause) {
			return 0;
		}
		// console.log('pause', pause.getHours() * 60 + pause.getMinutes())
		return pause.getHours() * 60 + pause.getMinutes(); // Convert pause time to minutes
	}

	isPauseRequired() {
		const to = this.toValue;
		const from = this.fromValue;
		if (!to || !from) {
			return false;
		}
		const diffMs = to.getTime() - from.getTime();
		const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes
		if (diffMins >= 540 && this.calculatePauseMins(this.pauseValue) < 45) {
			this.pauseWarningMessage = 'Die Pause muss mindestens 45 Minuten betragen.';

			return true;
		}
		if (diffMins >= 360 && this.calculatePauseMins(this.pauseValue) < 30) {
			this.pauseWarningMessage = 'Die Pause muss mindestens 30 Minuten betragen.';
			return true;
		}

		return false;
	}

	navigate(direction: string) {
		const newDate = new Date(this.selectedDate); // Clone the current selected date

		if (direction === 'next') {
			newDate.setMonth(this.selectedDate.getMonth() + 1);
		} else if (direction === 'prev') {
			newDate.setMonth(this.selectedDate.getMonth() - 1);
		}

		this.selectedDate = newDate;
		this.timeService.calculateTargetWorkingHours(this.currentEmployee);
		this.timeService.calculateValues(this.currentEmployee.timeEntries || []);
	}

	onPopupOpen(args: any): void {
		args.cancel = this.authService.currentEmployee.role !== 'admin';

		if (args.type === 'QuickInfo' && args.element) {
			// Change the text of the edit and delete buttons
			let editButton: HTMLElement = args.element.querySelector('.e-event-edit') as HTMLElement;
			let deleteButton: HTMLElement = args.element.querySelector('.e-event-delete') as HTMLElement;
			if (editButton) {
				editButton.innerText = 'Bearbeiten';
			}
			if (deleteButton) {
				deleteButton.innerText = 'Löschen';
			}
		}
		if (args.type === 'DeleteAlert' && args.element) {
			// Change the text of the edit and delete buttons
			let noButton: HTMLElement = args.element.querySelector('.e-quick-dialog-cancel') as HTMLElement;
			let yesButton: HTMLElement = args.element.querySelector('.e-quick-dialog-delete') as HTMLElement;
			let dialog: HTMLElement = args.element.querySelector('.e-dlg-content') as HTMLElement;
			let header: HTMLElement = args.element.querySelector('.e-dlg-header') as HTMLElement;
			if (yesButton) {
				yesButton.innerText = 'Löschen';
			}
			if (noButton) {
				noButton.innerText = 'Abbrechen';
			}
			if (dialog) {
				dialog.innerText = 'Sind Sie sicher, dass Sie diesen Zeiteintrag löschen möchten?';
			}
			if (header) {
				header.innerText = 'Zeiteintag löschen';
			}
		}
	}

	ngOnDestroy(): void {
		if(this.scheduleObj){
			this.scheduleObj.destroy();
			this.scheduleObj = null;
		}
		this.$destroy.next();
		this.$destroy.complete();	
	}
}
