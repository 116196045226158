import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { DynamicMedia } from '../models/dynamic-media.model'
import { CreateDynamicMediaDto } from '../dto/create-dynamic-media.dto'


@Injectable({
	providedIn: 'root'
})
export class DynamicMediaHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/dynamic-media`
	}

	public findAll(): Observable<DynamicMedia[]> {

		return this.http.get<DynamicMedia[]>(this.url)
	}

	public findOne(id: string): Observable<DynamicMedia> {

		return this.http.get<DynamicMedia>(`${this.url}/${id}`)
	}

	public create(dynamicMedia: any): Observable<DynamicMedia> {

		return this.http.post<DynamicMedia>(this.url, dynamicMedia)
	}

	public update(id:string, dynamicMedia: any): Observable<DynamicMedia> {

		return this.http.put<DynamicMedia>(`${this.url}/${id}`, dynamicMedia)
	}

	public delete(dynamicMedia: DynamicMedia): Observable<DynamicMedia> {

		return this.http.delete<DynamicMedia>(`${this.url}/${dynamicMedia.id}`)
	}
}
