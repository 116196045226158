import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { DynamicMediaHttpRequestService } from './dynamic-media-http-request.service';
import { DynamicMediaCacheService } from './dynamic-media-cache.service';
import { DynamicMedia } from '../models/dynamic-media.model';

@Injectable({
  providedIn: 'root'
})
export class DynamicMediaService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  public constructor(
    private readonly dynamicMediaHttpRequestService: DynamicMediaHttpRequestService,
    private readonly engineService: EngineService,
    private readonly dynamicMediaCacheService: DynamicMediaCacheService
  ) {
    this.engineService.appendProcessorForMany('DynamicMedia', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('DynamicMedia', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.dynamicMediaHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('DynamicMedia', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.dynamicMediaHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('DynamicMedia', 'findOne', request));
  }

  public create(dynamicMedia: any): void {
    const request = this.dynamicMediaHttpRequestService.create(dynamicMedia);
    this.engineService.execute(new HttpRequest('DynamicMedia', 'create', request));
  }

  public update(id:string, dynamicMedia: any): void {
    const request = this.dynamicMediaHttpRequestService.update(id, dynamicMedia);
    this.engineService.execute(new HttpRequest('DynamicMedia', 'update', request));
  }

  public delete(dynamicMedia: DynamicMedia): void {
    const request = this.dynamicMediaHttpRequestService.delete(dynamicMedia);
    this.engineService.execute(new HttpRequest('DynamicMedia', 'delete', request));
  }

  public processMany(action: string, dynamicMedia: DynamicMedia[]): void {
    switch (action) {
      case 'findAll':
        this.dynamicMediaCacheService.dynamicMedia = dynamicMedia;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.dynamicMediaCacheService.insertOrUpdateMany(dynamicMedia);
        break;
      case 'update':
        this.dynamicMediaCacheService.insertOrUpdateMany(dynamicMedia);
        break;
      case 'delete':
        this.dynamicMediaCacheService.deleteMany(dynamicMedia);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }

  public processOne(action: string, dynamicMedia: DynamicMedia): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.dynamicMediaCacheService.insertOrUpdateOne(dynamicMedia);
        break;
      case 'create':
        this.dynamicMediaCacheService.insertOrUpdateOne(dynamicMedia);
        break;
      case 'update':
        this.dynamicMediaCacheService.insertOrUpdateOne(dynamicMedia);
        break;
      case 'delete':
        this.dynamicMediaCacheService.deleteOne(dynamicMedia);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }
}
