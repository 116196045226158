import { Injectable } from '@angular/core';
import { DynamicMedia } from '../models/dynamic-media.model';

@Injectable({
  providedIn: 'root'
})
export class DynamicMediaCacheService {
  public dynamicMedia: DynamicMedia[] = [];

  public insertOrUpdateMany(dynamicMedia: DynamicMedia[]): void {
    for (const media of dynamicMedia) {
      this.insertOrUpdateOne(media);
    }
  }

  public insertOrUpdateOne(media: DynamicMedia): void {
    const index = this.dynamicMedia.findIndex(t => t.id === media.id);

    if (index === -1) {
      this.dynamicMedia.push(media);
    } else {
      this.dynamicMedia[index] = media;
    }
  }

  public deleteMany(dynamicMedia: DynamicMedia[]): void {
    for (const media of dynamicMedia) {
      this.deleteOne(media);
    }
  }

  public deleteOne(media: DynamicMedia): void {
    const index = this.dynamicMedia.findIndex(t => t.id === media.id);

    if (index === -1) {
      return;
    }

    this.dynamicMedia.splice(index, 1);
  }
}
